import {
    SiCplusplus,
    SiCss3,
    SiFigma,
    SiFramer,
    SiGithub, SiHtml5, SiJavascript, SiNeovim,
    SiNextdotjs,
    SiReact,
    SiRust,
    SiTailwindcss,
    SiTypescript,
    SiZig
} from "react-icons/si";
import { IconType } from "react-icons";

export type ProjectProps = {
    id: number;
    name: string;
    description: string;
    technologies: IconType[];
    techNames: string[];
    techLinks: string[];
    github: string;
    demo: string;
    image: string;
    available: boolean;
    imageStyle?: React.CSSProperties; // Add this property
};


export const projects = [
    {
        id: 0,
        name: "Escape now",
        description: "Don't be tied down",
        technologies: [],
        techNames: [],
        techLinks: [],
        github: "https://github.com/nerkoux/incolnx",
        demo: "https://monitize-mastery.mini.site", // Link to purchase or demo page
        image: "/projects/pepe.webp",
        available: true, // Indicates the "Buy Now" button should be shown
    },
    // Add more projects as needed, ensuring each has the relevant fields for the "Buy Now" button
];
